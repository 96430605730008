import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Login from './pages/login/Login';
import AdmLogin from './pages/adm/AdmLogin'
import Register from './pages/register/Register';
import Home from './pages/home/Home';
import UserDetails from './pages/user/UserDetails';

const ProtectedRoute = () => {
  const token = sessionStorage.getItem('token');
  if (token == null) {
    return <Navigate to={"/login"} replace />;
  }
  return <Outlet />
}

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route index path='/' element={(<Home />)} />
        </Route>
        <Route path='/register' element={(<Register />)} />
        <Route path='/login' element={(<Login />)} />
        <Route path='/adm/login' element={(<AdmLogin />)} />
        <Route path="/exibir/documentos/:cr" element={(<UserDetails />)}/>
      </Routes>
    </BrowserRouter>
  );
};

export default App;