import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/pt-br'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { API_URL } from '../../Vars';
import { Dropdown } from 'primereact/dropdown';


function Register() {

    const [conta, setConta] = useState({})

    const navigate = useNavigate();
    const rms = ["Cmdo 1ª RM","Cmdo 2ª RM","Cmdo 3ª RM","Cmdo 4ª RM","Cmdo 5ª RM","Cmdo 6ª RM","Cmdo 7ª RM","Cmdo 8ª RM","Cmdo 9ª RM","Cmdo 10ª RM","Cmdo 11ª RM","Cmdo 12ª RM",]

    function cadastrar() {
        axios.post(`${API_URL}/conta/registrar`, conta)
            .then(() => {
                navigate('/', { message: 'OK' })
            })
            .catch((err) => console.log(err))
    }

    function consultaCep() {
        if (conta.cep != null && conta.cep.length >= 8) {
            var cepF = conta.cep.replace(/\D/g, '');
            console.log(cepF);
            axios.get(`https://viacep.com.br/ws/${cepF}/json/`)
                .then((res) => {
                    if (res.data.erro == null) {
                        setConta({ ...conta, endereco: res.data.logradouro, complemento: res.data.complemento, bairro: res.data.bairro, cidade: res.data.localidade, uf: res.data.uf })
                    }
                    else {
                        limparEnd();
                    }
                })
                .catch(ex => console.log(ex))
        }
        else {
            limparEnd();
        }
    }

    function handleChange(e) {
        setConta({ ...conta, [e.target.id]: e.target.value })
    }

    function handleDateChange(e) {
        dayjs.extend(customParseFormat);
        var nascimento = dayjs(e.target.value, "DD/MM/YYYY", "pt-br")
        setConta({ ...conta, [e.target.id]: nascimento })
    }

    function limparEnd() {
        setConta({ ...conta, endereco: "", complemento: "", bairro: "", cidade: "", uf: "" })
    }

    return (

        <div className="flex align-items-center justify-content-center">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-8">
                <div className="text-left mb-5">
                    <img src="/images/logo_caccard.svg" alt="" height={40} className="mb-3" />
                    <div className="text-900 text-2xl font-medium mb-3">Registrar</div>
                </div>

                <div>
                    <label htmlFor="nome" className="block text-900 font-medium mb-2">Nome:</label>
                    <InputText id="nome"
                        type="text"
                        //placeholder="Nome"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="cpf" className="block text-900 font-medium mb-2">CPF:</label>
                    <InputMask id="cpf"
                        type="text"
                        mask="999.999.999-99"
                        //placeholder="000.000.000-00"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="nascimento" className="block text-900 font-medium mb-2">Nascimento:</label>
                    <InputMask id="nascimento"
                        onBlur={handleDateChange}
                        mask="99/99/9999"
                        className="mb-3"
                    />

                    <label htmlFor="cep" className="block text-900 font-medium mb-2">CEP:</label>
                    <InputMask id="cep"
                        mask='99999-999'
                        //placeholder="CEP"
                        onChange={handleChange}
                        onBlur={consultaCep}
                        className="w-full mb-3" />

                    <label htmlFor="endereco" className="block text-900 font-medium mb-2">Endereço:</label>
                    <InputText id="endereco"
                        type="text"
                        //placeholder="Endereço"
                        disabled
                        value={conta.endereco}
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="numero" className="block text-900 font-medium mb-2">Número:</label>
                    <InputText id="numero"
                        type="text"
                        //placeholder="Número"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="complemento" className="block text-900 font-medium mb-2">Complemento:</label>
                    <InputText id="complemento"
                        type="text"
                        //placeholder="Complemento"
                        value={conta.complemento}
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="bairro" className="block text-900 font-medium mb-2">Bairro:</label>
                    <InputText id="bairro"
                        type="text"
                        //placeholder="Bairro"
                        disabled
                        value={conta.bairro}
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="cidade" className="block text-900 font-medium mb-2">Cidade:</label>
                    <InputText id="cidade"
                        type="text"
                        //placeholder="Cidade"
                        disabled
                        value={conta.cidade}
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="uf" className="block text-900 font-medium mb-2">UF:</label>
                    <InputText id="uf"
                        type="text"
                        //placeholder="UF"
                        disabled
                        value={conta.uf}
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="telefone" className="block text-900 font-medium mb-2">Telefone:</label>
                    <InputMask id="telefone"
                        mask="(99) 9999?9-9999"
                        //placeholder="(xx) 99999-9999"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="cr" className="block text-900 font-medium mb-2">CR:</label>
                    <InputMask id="cr"
                        mask="999.999.999-99"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="rm" className="block text-900 font-medium mb-2">RM:</label>
                    <Dropdown id="rm"
                        value={conta.rm}
                        options={rms}
                        onChange={handleChange}
                        className="w-full mb-3" />

                    {/* <label htmlFor="atividades" className="block text-900 font-medium mb-2">Atividades:</label>
                    <InputTextarea id="atividades"
                        type="text"
                        value={conta.atividades}
                        onChange={handleChange}
                        className="w-full mb-3" /> */}

                    <label htmlFor="cupom" className="block text-900 font-medium mb-2">Código Promocional:</label>
                    <InputText id="cupom"
                        type="text"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="email" className="block text-900 font-medium mb-2">e-Mail:</label>
                    <InputText id="email"
                        type="text"
                        //placeholder="e-Mail"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="senha" className="block text-900 font-medium mb-2">Senha:</label>
                    <InputText id="senha"
                        type="password"
                        //placeholder="Senha"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="csenha" className="block text-900 font-medium mb-2">Confirmar Senha:</label>
                    <InputText id="csenha"
                        type="password"
                        //placeholder="Confirmar Senha"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <Button onClick={cadastrar} label="Cadastrar" icon="pi pi-user" className="w-full" />
                </div>
            </div>
        </div>

    );
}

export default Register;