import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/pt-br'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { API_URL } from '../../Vars';

function Home() {

    const navigate = useNavigate();
    const [documentos, setDocumentos] = useState([]);
    const [visible, setVisible] = useState(false);
    const [showDoc, setShowDoc] = useState(false);
    const fileUploadRef = useRef(null);
    const accessToken = sessionStorage.getItem('token');
    const [descricao, setDescricao] = useState("");
    const [validade, setValidade] = useState("");
    const [pdfData, setPdfData] = useState("");

    function deslogar() {
        sessionStorage.removeItem('token');
        navigate('/login');
    }

    function findDocs() {
        axios.get(`${API_URL}/documentos/listar`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setDocumentos(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    function exibirDoc(docId) {
        axios.get(`${API_URL}/documentos/carregar/${docId}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setPdfData(response.data.conteudo);
            })
            .catch(error => {
                console.error(error);
            });
        setShowDoc(true);
    }

    function excluirDoc(docId) {
        const isConfirmed = window.confirm("Tem certeza de que deseja excluir este arquivo?");
        if (isConfirmed) {
            axios.delete(`${API_URL}/documentos/excluir/${docId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    findDocs();
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    useEffect(() => {
        findDocs();
    }, [])

    const validadeTemplate = (documento) => {
        return <span>{documento.validade && format(parseISO(documento.validade), "dd/MM/yyyy")}</span>
    }

    const excluirDocTemplate = (documento) => {
        return <>
            <Button label="Visualisar" icon="pi pi-eye" severity="info" text onClick={() => exibirDoc(documento.id)} />
            <Button label="Excluir" icon="pi pi-trash" severity="danger" text onClick={() => excluirDoc(documento.id)} />
        </>
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Documentos</span>
            <Button icon="pi pi-plus" label='Novo' raised onClick={() => setVisible(true)} />
        </div>
    );

    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        var base64Data;
        reader.onload = (e) => {
            base64Data = e.target.result;
        }

        reader.readAsDataURL(file);

        reader.onloadend = function () {
            var doc = { nome: file.name, descricao: descricao, validade: validade, conteudo: base64Data };
            axios.post(`${API_URL}/documentos/upload`, doc, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    fileUploadRef.current.clear();
                    findDocs();
                    setVisible(false);
                })
                .catch(error => {
                    console.error(error);
                });
        };
    };

    function handleDateChange(e) {
        dayjs.extend(customParseFormat);
        var dtVal = dayjs(e.target.value, "DD/MM/YYYY", "pt-br")
        setValidade(dtVal)
    }

    return (


        <div className="surface-0">

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <img src="/images/logo_caccard.svg" alt="" height={50} className="mb-3" />
                </div>
                <div className="mt-3 lg:mt-0">
                    <Button onClick={deslogar} label="Sair" icon="pi pi-sign-out" severity='secondary' />
                </div>
            </div>

            <DataTable value={documentos} size='small' header={header} stripedRows tableStyle={{ minWidth: '50rem' }}>
                <Column field="descricao" header="Descrição"></Column>
                <Column field="validade" body={validadeTemplate} header="Validade"></Column>
                <Column body={excluirDocTemplate} />
            </DataTable>

            <Dialog header="Documento" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <label htmlFor="descricao" className="block text-900 font-medium mb-2">Descrição:</label>
                <InputText id="descricao"
                    type="text"
                    onChange={(e) => setDescricao(e.target.value)}
                    className="w-full mb-3" />
                <label htmlFor="validade" className="block text-900 font-medium mb-2">Validade:</label>
                <InputMask id="validade"
                    onBlur={handleDateChange}
                    mask="99/99/9999"
                    className="mb-3"
                />
                <FileUpload name="arquivo"
                    ref={fileUploadRef}
                    mode="basic"
                    accept="application/pdf"
                    chooseLabel="Arquivo"
                    customUpload
                    uploadHandler={customBase64Uploader}
                    maxFileSize={10000000} />
            </Dialog>

            <Dialog visible={showDoc} style={{ width: '85%', height: '100%' }} onHide={() => setShowDoc(false)}>
                <embed
                    src={pdfData}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </Dialog>

        </div>


    );
}

export default Home;