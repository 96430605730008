import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import * as moment from 'moment'
import { Dialog } from 'primereact/dialog';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { API_URL } from '../../Vars';

function UserDetails() {
  // Use the useParams hook to access the URL parameters
  const { cr } = useParams();
  const [dados, setDados] = useState([]);
  const [dtNasc, setDtNasc] = useState("");
  const [showDoc, setShowDoc] = useState(false);
  const [pdfData, setPdfData] = useState("");

  function loadData() {
    axios.get(`${API_URL}/pessoas/listar/documentos/${cr}`)
        .then(response => {
            setDados(response.data);
            setDtNasc(moment(response.data.nascimento).format('DD/MM/YYYY'));
        })
        .catch(error => {
            console.error(error);
        });
    }

    function exibirDoc(docId) {
        axios.get(`${API_URL}/documentos/carregar/${docId}`)
            .then(response => {
                setPdfData(response.data.conteudo);
            })
            .catch(error => {
                console.error(error);
            });
        setShowDoc(true);
    }

    useEffect(() => {
        loadData();
    }, [])


  return (
    <div className="surface-0">

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <img src="/images/logo_caccard.svg" alt="" height={50} className="mb-3" />
                </div>
            </div>
            
            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>Nome: </strong>{dados.nome}</p>
            </div>
            
            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>CPF: </strong>{dados.cpf}</p>
            </div>

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>CR: </strong>{dados.cr}</p>
            </div>

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>Nascimento: </strong>{dtNasc}</p>
            </div>
            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>Endereço: </strong>{dados.endereco}, {dados.numero} {dados.complemento} - {dados.bairro} - {dados.cidade}/{dados.uf} - {dados.cep}</p>
            </div>

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>Telefone: </strong>{dados.telefone}</p>
            </div>

            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <p><strong>e-Mail: </strong>{dados.email}</p>
            </div>

            {dados.documentos && dados.documentos.map(doc => (
                <>
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <Button id={doc.id} label={doc.descricao} icon="pi pi-file" severity="info" outlined onClick={() => exibirDoc(doc.id)}/>
                    </div>
                    <br/>
                </>
            ))}

            <Dialog visible={showDoc} style={{ width: '85%', height: '100%' }} onHide={() => setShowDoc(false)}>
                <embed
                    src={pdfData}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </Dialog>
    </div>
  );
}

export default UserDetails;
