import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { API_URL } from '../../Vars';

function Login() {

    //const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [conta, setConta] = useState({})
    const [msg, setMsg] = useState(null);

    function handleChange(e) {
        setConta({ ...conta, [e.target.id]: e.target.value })
    }

    function logar() {
        axios.post(`${API_URL}/login/auth`, conta)
            .then((response) => {
                sessionStorage.setItem('token', response.data.token)
                setMsg(null);
                navigate('/', { message: 'Deu Boa' })
            }).catch((err) => {
                setMsg("Usuário inexistente e/ou Senha incorreta!");
            }
            );
    }

    return (
        <div className="flex align-items-center justify-content-center">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
                <div className="text-center mb-5">
                    <img src="/images/logo_caccard.svg" alt="" height={50} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">Bem Vindo</div>
                    <span className="text-600 font-medium line-height-3">Não possui uma conta?</span>
                    <a href='/register' className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Registre-se!</a>
                </div>

                <div>
                    { msg &&
                        <Message severity="error" text={msg} className="block text-900 font-medium mb-2"/>
                    }
                    <label htmlFor="usuario" className="block text-900 font-medium mb-2">e-Mail</label>
                    <InputText id="usuario"
                        type="text"
                        placeholder="e-Mail"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <label htmlFor="senha" className="block text-900 font-medium mb-2">Senha</label>
                    <InputText id="senha"
                        type="password"
                        placeholder="Senha"
                        onChange={handleChange}
                        className="w-full mb-3" />

                    <div className="flex align-items-center justify-content-between mb-6">
                        <div className="flex align-items-center">
                            <Checkbox id="rememberme" className="mr-2" />
                            <label htmlFor="rememberme">Manter-se Conectado</label>
                        </div>
                        <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Esqueceu sua senha?</a>
                    </div>

                    <Button
                        label="Entrar"
                        icon="pi pi-user"
                        onClick={logar}
                        className="w-full" />
                </div>
            </div>
        </div>

    );
}

export default Login;